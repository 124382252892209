'use client';

import { Avatar, Box, Button, Checkbox, Flex, Popover, Text, TextArea } from '@radix-ui/themes';
import { PersonIcon } from '@radix-ui/react-icons';
import { useSession } from 'next-auth/react';
import { FaCog } from 'react-icons/fa'; // Importing the gear icon
import * as Dialog from '@radix-ui/react-dialog';
import { choicesSI, choicesUSC } from '@/lib/shared/unitprovider';
import useUnitsStore from '@/stores/useUnitsStore'; // Adjust the path to your store
import { useRouter } from 'next/navigation'; // Import the useRouter hook from next/navigation

function UserProfile() {
  const sessionData = useSession();
  const units = useUnitsStore((state) => state.units);
  const setUnit = useUnitsStore((state) => state.setUnit);
  const setCurrentSystem = useUnitsStore((state) => state.setCurrentSystem);
  const router = useRouter(); // Initialize the router

  const handleUnitChange = (unitType, unitKey) => (event) => {
    setUnit(unitType, unitKey, event.target.value);
  };

  const handleSystemChange = (event) => {
    setCurrentSystem(event.target.value);
  };

  const handleDialogClose = () => {
    router.refresh(); // Refresh the current page
  };

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold text-sm py-2 px-4 rounded">
          <PersonIcon width="16" height="16" />
          Profile
        </Button>
      </Popover.Trigger>
      <Popover.Content className="p-4 bg-white rounded-lg shadow-lg w-[360px]">
        <pre className="text-xs">{JSON.stringify(sessionData, null, 2)}</pre>

        <Dialog.Root>
          <Dialog.Trigger asChild>
            <Button className="mt-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold text-sm py-2 px-4 rounded flex items-center">
              <FaCog className="mr-2" />
              Preferences
            </Button>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            <Dialog.Content
              className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl"
              onPointerDownOutside={(event) => event.preventDefault()} // Prevent closing on outside click
              onInteractOutside={(event) => event.preventDefault()}    // Prevent closing on any outside interaction
            >
              <Dialog.Title className="text-lg font-semibold">Preferences</Dialog.Title>
              <div className="flex flex-col gap-6 mt-4">

                {/* Dropdown for language selection */}
                <div className="flex items-center gap-4">
                  <h2 className="text-base font-medium w-60">Language:</h2>
                  <select
                    name="languageSelect"
                    id="languageSelect"
                    className="border border-gray-300 rounded-md p-2 text-sm w-56 ml-2"
                  >
                    <option value="english">English</option>
                  </select>
                </div>

                {/* Radio Buttons for System Selection */}
                <div className="flex items-center gap-4">
                  <h2 className="text-base font-medium w-60">Current System:</h2>
                  <label className="flex text-base font-medium items-center w-56 pl-3">
                    <input
                      type="radio"
                      name="unitSystem"
                      value="SI"
                      checked={units.currentSystem === 'SI'}
                      onChange={handleSystemChange}
                      className="mr-2"
                    />
                    International System
                  </label>
                  <label className="flex text-base font-medium items-center pl-2">
                    <input
                      type="radio"
                      name="unitSystem"
                      value="USC"
                      checked={units.currentSystem === 'USC'}
                      onChange={handleSystemChange}
                      className="mr-2"
                    />
                    US Customary
                  </label>
                </div>

                <h2 className="text-base font-medium -mb-4">Units of measure:</h2>
                <div className="flex flex-col gap-2">
                  {/* Labels for SI and USC columns */}
                  <div className="flex items-center gap-2">
                    <div className="w-56"></div> {/* Empty space to align labels */}
                    <div className="flex-grow text-center font-medium text-sm">International System</div>
                    <div className="flex-grow text-center font-medium text-sm">US Customary</div>
                  </div>

                  {Object.keys(units.SI).map((key) => (
                    <div key={key} className="flex items-center gap-4">
                      <label htmlFor={`SI_${key}Select`} className="w-96 font-medium text-sm">
                        {units.labels[key]}
                      </label>
                      <select
                        id={`SI_${key}Select`}
                        name={`SI_${key}Select`}
                        value={units.SI[key]}
                        onChange={handleUnitChange('SI', key)}
                        disabled={units.currentSystem !== 'SI'}
                        className={`flex-grow border border-gray-300 rounded-md p-2 text-sm ${
                          units.currentSystem !== 'SI' ? 'bg-gray-100 text-gray-600' : ''
                        }`}
                        style={{ minWidth: '220px' }} // Ensures consistent width for SI selects
                      >
                        {choicesSI(key).map((o, ind) => (
                          <option key={ind} value={o.unit}>{o.unicode}</option>
                        ))}
                      </select>
                      <select
                        id={`USC_${key}Select`}
                        name={`USC_${key}Select`}
                        value={units.USC[key]}
                        onChange={handleUnitChange('USC', key)}
                        disabled={units.currentSystem !== 'USC'}
                        className={`flex-grow border border-gray-300 rounded-md p-2 text-sm ${
                          units.currentSystem !== 'USC' ? 'bg-gray-100 text-gray-600' : ''
                        }`}
                        style={{ minWidth: '220px' }} // Ensures consistent width for USC selects
                      >
                        {choicesUSC(key).map((o, ind) => (
                          <option key={ind} value={o.unit}>{o.unicode}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <Dialog.Close asChild>
                  <Button
                    className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 text-sm"
                    onClick={handleDialogClose} // Handle dialog close
                  >
                    OK
                  </Button>
                </Dialog.Close>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>

      </Popover.Content>
    </Popover.Root>
  );
}

export default UserProfile;
